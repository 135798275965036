<template>
    <div>
        <dl>
            <dt class="dt-left" v-if="!$common.mobile()">{{ formalItem.name }}</dt>
            <dd
                    class="dd-rt"
                    :class="activeId == item.id ? 'activeList' : ''"
                    v-for="item in formalItem.subArticles"
                    :key="item.id"
                    @click="chooseOne(item)"
            >
                {{ item.name }}
            </dd>
        </dl>
    </div>
</template>

<script>
    export default {
        name: "SideBar",
        props: ["list"],
        data() {
            return {
                activeId: 0,
                hotArticlesArr: [],
                recommendArticles: [],
            };
        },
        computed: {
            formalItem: {
                get() {
                    return this.$store.state.formalItem;
                },
                set(val) {
                },
            },
            childItem: {
                get() {
                    return this.$store.state.childItem;
                },
                set(val) {
                },
            },
        },
        watch: {
            formalItem: {
                handler: function (newValue, oldValue) {
                    if (newValue.subArticles.length > 0) {
                        this.activeId = newValue.subArticles[0].id;
                    }
                },
                immediate: true,
            },
            childItem(newValue, oldValue) {
                if (newValue) {
                    this.activeId = newValue.id;
                    console.log(newValue.id,'this.activeIdthis.activeId')
                    this.$forceUpdate()
                }
            },
            activeId(newValue, oldValue) {
                this.formalItem.subArticles.map((item) => {
                    if (item.id == newValue) {
                        console.log(item.id,'iiiiiiiiiiiiiiiiiiiiiaaa')
                        this.$store.commit("setChildItem", item);
                    }
                });
            },
        },
        mounted() {
            if (this.childItem) {
                this.activeId = this.childItem.id;
            } else {
                if (this.formalItem.subArticles.length > 0) {
                    this.activeId = this.formalItem.subArticles[0].id;
                    console.log('2222222222222222222222')
                }
            }
        },
        methods: {
            chooseOne(data) {
                this.activeId = data.id;
                this.$router.push({path: "/secondPage", query: {columnId: data.id}});
                if (this.$router.history.current.name == "DetailPage") {
                    this.formalItem.subArticles.map((item) => {
                        if (item.id == data.id) {
                            this.$store.commit("setChildItem", item);
                        }
                    });
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .dt-left {
        text-align: left;
        color: #850416;
        font-size: 32px;
        font-weight: 400;
        margin-left: 42px;
    }

    .dd-rt {
        margin: 24px 0 0 0px;
    }

    .activeList {
        color: #850416 !important;
    }
</style>
