<template>
    <div class="contain " :style="$common.mobile() ? 'margin-top: 50px;' : ''">
        <div
                class="title-sub"
                :style="$common.mobile() ? 'padding-left: 75px;' : ''"
        >
            {{ formalItem.name }} > {{ childItem.name }}
        </div>
        <div class="fl_r contain-sub fr-jub" v-if="!$common.mobile()&&!$route.query.ifPrice">
            <div class="sliderSty">
                <side-bar/>
            </div>
            <div class="content">
                <template v-if="consultList.length > 0">
                    <template v-if="isContent && articleDetail">
                        <div class="fl_c al_c">
                            <div class="contentTitle">
                                {{ articleDetail.title }}
                            </div>
                            <div class="contentTime">
                                <!-- {{ articleDetail.showTime }} -->
                                <div class="time-list">
                                    {{ $t('detailPage.releaseTime') }}：{{ articleDetail.showTime }}
                                </div>
                                <div class="time-list">
                                    {{ $t('detailPage.source') }}：{{ articleDetail.source ? articleDetail.source :
                                    $t('detailPage.none') }}
                                </div>
                            </div>
                            <img
                                    class="contentImg"
                                    v-if="articleDetail.titleImg"
                                    :src="articleDetail.titleImg"
                                    alt=""
                            />
                            <div class="contentSty" v-html="articleDetail.content"></div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="right-title fl_r fr-jub">
                            <div class="name-left-til">
                                {{ childItem.name }}
                                <span class="color-1">Module</span>
                            </div>
                        </div>
                        <div class="notice-box">
                            <div

                                    class="inform-box fl_r fr-jub"
                                    v-for="item in tableData"
                                    :key="item.id"
                                    @click="goPage(item)"
                            >
                                <div class="fl_r" style="align-items: center;width: 100%">
                                    <div class="img-box">
                                        <img :src="item.titleImg" alt="" class="img-t"/>
                                    </div>
                                    <div class="text-ce-box fl_r ju_b">
                                        <div class="ptitle">{{ item.title }}</div>
                                        <div class="fl-r" style="white-space: nowrap">
                                            <span>{{ item.showTime }}</span>
                                            <i class="el-icon-arrow-right"></i>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page.sync="pagination.currentPage"
                                :page-size="pagination.pageSize"
                                layout="total, prev, pager, next"
                                :total="pagination.total">
                        </el-pagination>
                    </template>
                </template>
            </div>

        </div>
        <div class="priceCotain" v-if="$route.query.ifPrice">
            <div class="priceBox " v-for="(item,index) in priceList" :key="index">
                <div class="fl_c al_c mar_top1">
                    <div class="text">
                        <span class="mar_right">{{item.name}}</span>
                        <span style="color: rgba(129, 129, 129, 1)">{{$t('index.money')}} /{{item.unitQuantifier}}</span>
                    </div>
                    <div class="price" :style="{ color: item.settlePl>0 ? 'red' : 'green' }">{{item.price}}</div>
                    <div class="fl_r al_c icon">
                        <div>
                            <span :style="{ color: item.settlePl>0 ? 'red' : 'green' }">{{item.settlePl}}</span>
                            <i slot="reference" class="el-icon-top" style="color: red" v-if="item.settlePl>0"/>
                            <i slot="reference" class="el-icon-bottom" style="color: green" v-else/>
                        </div>
                        <div>
                            <span :style="{ color: item.settleRa>0 ? 'red' : 'green' }">{{ formatPercentage(item.settleRa) }}</span>
                            <i slot="reference" class="el-icon-top" style="color: red" v-if="item.settleRa>0"/>
                            <i slot="reference" class="el-icon-bottom" style="color: green" v-else/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="priceCotain content" v-if="$route.query.ifPrice"
             style="min-height: 200px">
            <template>
                <div class="right-title fl_r fr-jub">
                    <div class="name-left-til">
                        {{ childItem.name }}
                        <span class="color-1">Module</span>
                    </div>
                </div>
                <div class="notice-box">
                    <div
                            class="inform-box fl_r fr-jub"
                            v-for="item in specialList"
                            :key="item.id"
                            @click="goPage(item)"
                    >
                        <div class="fl_r" style="align-items: center">
                            <div class="img-box">
                                <img :src="item.titleImg" alt="" class="img-t"/>
                            </div>
                            <div class="text-ce-box fl_r"
                                 :style="$common.mobile() ? 'width: 200px;' : ''">
                                <p style="width: 100%">{{ item.title }}</p>
                                <!--                                        <span>{{ item.showTime }}</span>-->
                            </div>
                        </div>
                        <!-- <div><i class="el-icon-arrow-right"></i></div> -->
                    </div>
                </div>
            </template>
        </div>
        <div class="contain-sub ph--type-box" v-if="$common.mobile()">
            <div class="content" style="width: 100%">
                <template v-if="consultList.length > 0">
                    <template v-if="isContent && articleDetail">
                        <div class="fl_c al_c">
                            <div class="contentTitle">
                                {{ articleDetail.title }}
                            </div>
                            <div class="contentTime fl_c">
                                <!-- {{ articleDetail.showTime }} -->
                                <div class="time-list" style="font-size: 15px">
                                    {{ $t('detailPage.releaseTime') }}：{{ articleDetail.showTime }}
                                </div>
                                <div class="time-list" style="font-size: 15px">
                                    {{ $t('detailPage.source') }}：{{ articleDetail.source ? articleDetail.source :
                                    $t('detailPage.none') }}
                                </div>
                            </div>
                            <img
                                    class="contentImg"
                                    v-if="articleDetail.titleImg"
                                    :src="articleDetail.titleImg"
                                    alt=""
                            />
                            <div class="contentSty" v-html="articleDetail.content"></div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="right-title fl_r fr-jub">
                            <div class="name-left-til">
                                {{ childItem.name }}
                                <span class="color-1">Module</span>
                            </div>
                        </div>
                        <div class="notice-box">
                            <div
                                    class="inform-box fl_r fr-jub"
                                    v-for="item in consultList"
                                    :key="item.id"
                                    @click="goPage(item)"
                            >
                                <div class="fl_r" style="align-items: center">
                                    <div class="img-box">
                                        <img :src="item.titleImg" alt="" class="img-t"/>
                                    </div>
                                    <div class="text-ce-box fl_r"
                                         :style="$common.mobile() ? 'width: 200px;' : ''">
                                        <p style="width: 100%">{{ item.title }}</p>
                                        <!--                                        <span>{{ item.showTime }}</span>-->
                                    </div>
                                </div>
                                <!-- <div><i class="el-icon-arrow-right"></i></div> -->
                            </div>
                        </div>
                    </template>
                </template>
            </div>
            <div class="sliderSty sliderSty-ph">
                <side-bar/>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        getarticlesInfo,
        getDownload,
        getarticleDetail,
        getColumnTree,
    } from "../../utils/api";
    import SideBar from "../../components/SideBar/SideBar";
    import {getPrice} from '@/utils/api/index.js'

    export default {
        components: {SideBar},
        data() {
            return {
                activeId: null,
                consultList: [],
                tableData: [],
                isContent: false,
                articleDetail: null,
                priceList: [],
                specialList: [],
                pagination: {
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                }
            };
        },
        computed: {
            formalItem: {
                get() {
                    return this.$store.state.formalItem;
                },
                set(val) {
                },
            },
            childItem: {
                get() {
                    return this.$store.state.childItem;
                },
                set(val) {
                },
            },
        },
        watch: {
            formalItem: {
                handler: function (newValue, oldValue) {
                    // console.log("----formalItem", newValue);
                    if (newValue.subArticles.length > 0) {
                        this.activeId = newValue.subArticles[0].id;
                    }
                },
                immediate: true,
            },
            childItem(newValue, oldValue) {
                // console.log("----childItem", newValue);
                if (newValue) {
                    this.getarticlesInfo(newValue.id);
                }
            },
            activeId(newValue, oldValue) {
                this.formalItem.subArticles.map((item) => {
                    if (item.id == newValue) {
                        this.$store.commit("setChildItem", item);
                    }
                });
            },
        },
        mounted() {
            if (this.$route.query.columnId) {
                this.getColumnTree1(this.$route.query.columnId);
            }
            if (!this.$store.state.formalItem) {
                return this.$router.push("/");
            }
            // if (this.isWeixinBrowser()) {
            //     this.isweixin= true;
            //     this.$store.commit('setWeixing', true);
            // }else{
            //     this.$store.commit('setWeixing', false);
            // }
            window.scrollTo(0, 0);
            if (this.$route.query.id) {
                this.getColumnTree(this.$route.query.id);
            }
            // this.getDownload();
            if (this.childItem) {
                this.getarticlesInfo(this.childItem.id);
            } else {
                if (this.formalItem.subArticles.length > 0) {
                    this.getarticlesInfo(this.formalItem.subArticles[0].id);
                } else {
                    this.getarticlesInfo(this.formalItem.id);
                }
            }
            if (this.$route.query.ifPrice) {
                this.getarticlesInfo(483)
                this.getPrice()
                this.timer = setInterval(() => {
                    this.getPrice();
                }, 5000);
            }
        },
        methods: {
            formatPercentage(value) {
                return (value * 100).toFixed(2) + "%";
            },
            async getPrice() {
                let res = await getPrice()
                this.priceList = res.data
            },
            async getColumnTree1(columnId) {
                let res = await getColumnTree();
                res.data.map((item) => {
                    item.showSecondTab = false;
                    item.subArticles.map((item1) => {
                        if (item1.id == columnId) {
                            this.$store.commit("setFormalItem", item);
                            this.$store.commit("setChildItem", item1);
                        }
                    });
                });
            },
            async getColumnTree(id) {
                let res = await getColumnTree();
                res.data.map((item) => {
                    item.showSecondTab = false;
                    if (item.id == id) {
                        this.$store.commit("setFormalItem", item);
                        this.$store.commit("setChildItem", item.subArticles[0]);
                    }
                });
                // this.headerList = res.data;
            },
            async getarticlesInfo(id) {
                let res = await getarticlesInfo({
                    columnId: id,
                });
                this.specialList = res.data
                this.isContent = res.data.length == 1 ? true : false;
                if (this.formalItem.subArticles.length < 1) {
                    this.consultList = [];
                    this.pagination.total = 0
                } else {
                    this.pagination.total = res.data.length
                    this.consultList = res.data;
                    this.paging()
                }
                if (res.data.length == 1) {
                    this.getarticleDetail(res.data[0].id);
                }
            },
            async getarticleDetail(id) {
                let res = await getarticleDetail({
                    articleId: id,
                });
                this.articleDetail = res.data;
            },
            chooseOne(data) {
                this.activeId = data.id;
                this.$router.push({path: "/secondPage", query: {columnId: data.id}});
                if (this.$router.history.current.name == "DetailPage") {
                    this.formalItem.subArticles.map((item) => {
                        if (item.id == data.id) {
                            this.$store.commit("setChildItem", item);
                        }
                    });
                }
            },
            goPage(item) {
                this.$router.push({
                    path: "/detailPage",
                    query: {
                        articleId: item.id,
                        columnId: item.columnId,
                    },
                });
            },
            paging() {
                // 起始位置 = (当前页 - 1) x 每页的大小
                const start = (this.pagination.currentPage - 1) * this.pagination.pageSize;
                // 结束位置 = 当前页 x 每页的大小
                const end = this.pagination.currentPage * this.pagination.pageSize;
                this.tableData = this.consultList.slice(start, end);
            },
            handleCurrentChange(val) {
                this.pagination.currentPage = val;
                this.getarticlesInfo(this.$route.query.columnId)
            },
            handleSizeChange(val) {
                this.pagination.currentPage = 1;
                this.pagination.pageSize = val;
                this.getarticlesInfo(this.$route.query.columnId)
            },
        },
    };
</script>
<style lang="scss" scoped>
    .fl_r {
        display: flex;
        flex-direction: row;
    }

    .fl-d {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .fr-jub {
        justify-content: space-between;
    }

    .contain {
        width: 100%;
        // width: 100vw;
        margin: 0 auto;
        margin-top: 440px;
        height: auto;

        .title-sub {
            width: 100%;
            background-color: #850416;
            height: 70px;
            line-height: 70px;
            color: #fff;
            font-size: 22px;
            font-weight: 400;
            padding-left: 169px;
            box-sizing: border-box;
        }

        .contain-sub {
            padding: 0px 125px 41px;
            box-sizing: border-box;
        }

        .ph--type-box {
            padding: 0px 40px 41px !important;
        }

        .content {
            // width: 930px;
            width: 76%;
            // border: 1px solid #ccc;
            margin-top: 41px;
            min-height: 800px;

            .contentTitle {
                font-weight: 500;
                color: #212121;
                font-family: "PingFang SC";
                font-size: 40px;
            }

            .contentTime {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                line-height: 50px;
                height: 50px;
                border-bottom: 2px solid #b70c04;
                text-align: center;
                margin: 30px 0;
                padding: 0 40px;
                box-sizing: border-box;
                color: #757575;
                font-family: "PingFang SC";
                font-size: 20px;
                font-weight: 400;
            }

            .contentImg {
                width: 100%;
                height: 100%;
            }

            .contentSty {
                margin-top: 20px;
            }

            .right-title {
                height: 45px;
                line-height: 45px;
                box-sizing: border-box;
                border-bottom: 2px solid #f2f2f2;

                .name-left-til {
                    color: #454545;
                    font-size: 32px;
                    box-sizing: border-box;
                    padding-bottom: 44px;
                    border-bottom: 2px solid #b70c04;
                }

                .check-type-ri {
                    color: #b0b0b0;
                    text-align: center;
                    font-size: 20px;

                    span {
                        &:nth-child(2) {
                            margin: 0 24px;
                        }

                        .el-icon-top {
                            font-size: 16px;
                        }
                    }
                }
            }

            .notice-box {
                margin-top: 19px;

                .btn-top {
                    .btn-1 {
                        width: 100px;
                        height: 46px;
                        color: #fff;
                        text-align: center;
                        font-size: 24px;
                        border: 0;

                        &:first-child {
                            margin-right: 8px;
                        }
                    }
                }

                .inform-box {
                    width: 100%;
                    height: 115px;
                    background: rgba(238, 238, 238, 0.67);
                    padding: 10px 40px 10px 26px;
                    box-sizing: border-box;
                    align-items: center;
                    margin-top: 16px;

                    .img-box {
                        width: 92px;
                        height: 92px;
                        line-height: 92px;
                        /*background-color: #b70c04;*/
                        color: #fff;
                        text-align: center;
                        font-size: 35px;
                        font-weight: 400;
                        margin-right: 24px;

                        .img-t {
                            width: 92px;
                            height: 92px;
                        }
                    }

                    .text-ce-box {
                        width: 90%;
                        height: 72px;
                        line-height: 72px;
                        color: #616161;
                        font-size: 20px;
                        font-weight: 500;
                        border-left: 1px solid #bababa;
                        padding-left: 24px;
                        box-sizing: border-box;

                        .ptitle {
                            width: auto;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #000000;
                        }

                        span {
                            margin-left: 10px;
                            font-size: 18px;
                            color: #939393;
                        }

                        i {
                            color: #939393;
                        }
                    }
                }
            }
        }
    }

    .bg-bus {
        background-image: url("../../assets/scPage/bus-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    ::v-deep .el-pagination {
        text-align: right;
        margin-top: 20px;
    }

    .sliderSty {
        // width: 260px;
        width: 20%;
        // background-color: #c1f4f9;
        padding-top: 41px;
        text-align: center;
        color: #d0d0d0;
        font-size: 24px;
        font-weight: 400;
        background: rgba(241, 241, 241, 0.8);
        box-sizing: border-box;

        .dt-left {
            text-align: left;
            color: #850416;
            font-size: 32px;
            font-weight: 400;
            margin-left: 42px;
        }

        .dd-rt {
            margin: 24px 0 0 0px;
        }
    }

    .sliderSty-ph {
        width: 100% !important;
        padding: 24px 0 24px !important;
    }

    .activeList {
        color: #850416 !important;
    }

    .actice-2 {
        background: #b70c04 !important;
    }

    .actice-3 {
        color: #b72d27 !important;
    }

    .color-1 {
        color: rgba(183, 12, 4, 0.16) !important;
    }

    .color-2 {
        color: #b70c04;
    }

    .priceCotain {
        width: 1510px;
        height: auto;
        margin: 100px 200px;
        overflow-x: auto;
        white-space: nowrap; /* 防止priceBox折行 */
        text-align: center;

        .priceBox {
            width: 350px;
            height: 272px;
            margin-right: 25px;
            box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
            border-top: 3px solid #850416;
            display: inline-block;
            margin-bottom: 5px;

            .mar_top1 {
                margin-top: 50px;
            }

        }

        .priceBox:first-child {
            margin-left: 5px;
        }
    }

    .text span {
        width: auto;
        height: 24px;
        font-size: 20px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 23px;
    }

    .price {
        width: auto;
        height: 52px;
        font-size: 48px;
        font-family: DINPro-Medium, DINPro;
        font-weight: 500;
        color: #FF4C34;
        line-height: 56px;
        margin: 15px 0;
    }

    .icon {
        width: auto;
        height: 24px;
        font-size: 22px;
        font-family: DINPro-Regular, DINPro;
        font-weight: 400;
        color: #6B6B6B;
        line-height: 26px;

        i {
            margin-right: 10px;
        }

        span {
            margin-left: 10px;
        }
    }

    ::v-deep .el-pager li.active {
        color: #850416;
    }
    ::v-deep .el-pager li:hover{
        color: #850416;
    }
</style>
